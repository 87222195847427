@import "colors.scss";
@import "global.scss";

.quickdispensaries {
  width: 1021px;
  padding: 0;
  margin: 0 33px;
  text-align: left;
}
.quickdispensaries > * {
  padding: 0;
  margin: 0;
  min-width: 100%;
}
.dispensaries {
  text-align: left;
  margin: 0;
  padding: 0rem 2rem;
  width: 100%;
}
.dispensaries-table-data--type > svg {
  margin-right: 5px;
}

.dispensaries-table--body > tr {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // grid-template-columns: auto auto auto auto auto auto auto;
  grid-gap: 2rem;
  grid-auto-columns: minmax(max-content, 5fr);
  margin-top: 1rem;
  height: 56px;
  padding-left: 1rem;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
}
.dispensaries-table {
  width: 100%;
}
.dispensaries > h2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 28px;
  font-weight: 600;
  color: #0c0f13;
  margin-top: 2rem;
}

.dispensaries-table--body > tr:not(:first-child) {
  border-radius: 4px;
  border: solid 1px 1px 1px 0 #e6eaee;
  // border-left: solid 0.25rem red;
  background-color: white;
}
.dispensaries-table-data--priority-1 {
  border-left: solid 0.25rem green;
}
.dispensaries-table-data--priority-2 {
  border-left: solid 0.25rem orange;
}
.dispensaries-table-data--priority-3 {
  border-left: solid 0.25rem red;
}
.dispensaries-table-data-priority-text-1 {
  color: green;
  font-weight: bold;
}
.dispensaries-table-data-priority-text-2 {
  color: orange;
  font-weight: bold;
}
.dispensaries-table-data-priority-text-3 {
  color: red;
  font-weight: bold;
}
.dispensaries-table-data,
.dispensaries-table-headers {
  align-items: center;
}
.dispensaries-table-data--type > img {
  width: 1.25rem;
  padding-right: 0.25rem;
}
.dispensaries-table-data--dispensary {
  border-radius: 4px;
  // padding: 0.5rem;
  height: 1.5rem;
  justify-self: left;
  color: white;
  width: 100%;
  display: inline-block;
  line-height: 1.5rem;
}
.dispensary-1 {
  background-color: #1bb934;
}
.dispensary-2 {
  background-color: #18a5d6;
}
.dispensary-3 {
  background-color: #0c0f13;
}
.dispensaries-table-data--dispensary > p {
  position: relative;
  padding: 0 0.5rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.dispensaries-table-headers {
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8fa4;
  font-family: 'Source Sans Pro', sans-serif;
}

.dispensary-details {
  display: flex;
  flex-wrap: wrap;
  // grid-template-columns: auto auto auto auto;
  padding: 0 1rem;
  align-content: center;
  justify-content: inherit;
  gap: 2rem;
}

.dispensary-details > div {
  display: flex;
}

.dispensary-header {
  background-color: $light1;
  padding-right: 2rem;
}

.dispensary-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.dispensary-logo > img {
  width: 11.875rem;
}
.dispensary-details--info {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  align-content: center;
}
.dispensary-details--info,
.dispensary-details--address,
.dispensary-details--account {
  align-self: center;
  padding: 0rem 1rem;
  margin: 2rem 0rem;
  border-left: solid 1px #e6eaee;
}
.dispensary-details--info-panel {
  display: grid;
  grid-template-rows: auto auto auto auto;
  justify-items: left;
  align-items: center;
  text-align: left;
  gap: 0.175rem;
}
.dispensary-details--info-panel-item {
  display: grid;
}
.dispensary-details--info-panel-item > span {
  display: block;
}
.dispensary-details--info-panel-item-tag {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.58;
  letter-spacing: normal;
  color: #7f8fa4;
}
.dispensary-details--info-panel-item-prop {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #354052;
}
.dispensary-details--info-panel-hoursbox {
  width: 240px;
  border-radius: 4px;
  border: solid 1px #e6eaee;
  margin: 0.25rem 0 0 0;
  padding: 0.25rem;
  display: grid;
  grid-template-rows: auto auto auto auto auto auto;
}

.dispensary-details--info-panel-hoursbox-day {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  color: #354052;
  padding: 0 0.25rem;
}
.dispensary-details--info-panel-hoursbox-day,
.dispensary-details--info-panel-item-hours {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #354052;
}
.dispensary-detail-tabs
  > .tab-content
  > #dispensary-details-tabs-tabpane-patient
  > .patients.padding-left,
.dispensary-detail-tabs
  > .tab-content
  > #dispensary-details-tabs-tabpane-cases
  > .cases.padding-left {
  padding: 0;
}

.dispensary-detail-tabs {
  margin-right: 2rem;
  margin-top: 1rem;
}

.disipensary-detail-container {
  width: 100%;
  padding-top: 1rem;
}

.dispensary-info--parent {
  border-radius: 4px;
}

#dispensary-details-tabs-tabpane-patient {
  border-top: 1px solid #dee2e6;
  border-top-right-radius: 4px;
}

.dark {
  .dispensary-info--parent {
    background-color: $dark3;
  }

  .patient-detail--inner {
    background-color: $dark1;
    border-radius: inherit;
  }

  .calldetails-slider--grid--top {
    background-color: $dark1;
  }

  .dispensary-details--info-panel-item-prop,
  .dispensary-details--info-panel-hoursbox-day,
  .dispensary-details--info-panel-item-hours
  {
    color: $light1;
  }
}
