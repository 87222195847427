input[type='radio']:after {
  width: 16px;
  height: 16px;
  top: -1px;
  left: -1px;
  position: relative;
  content: '';
  display: inline-block;
  visibility: visible;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #ced0da;
  background-image: linear-gradient(to top, #f2f4f8, #feffff);
}

input[type='radio']:checked:after {
  top: -1px;
  left: -1px;
  position: relative;
  // TODO render check inside radio button
  // content: "\2713";
  // font-weight: 900;
  display: inline-block;
  visibility: visible;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #27aa11;
  background-image: linear-gradient(to top, #29b311, #57d841);
}