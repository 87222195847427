@import "colors.scss";

.call-slideout {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  right: 20rem;
  @media only screen and (min-width: 1030px) {
  }
}
.in-phone-logo {
  width: 11rem;
}
.nav-link:hover {
  color: #354052;
}
.nav-link {
  color: #354052;
  font-size: 14px;
  background-image: linear-gradient(to top, #f4f7fa, #ffffff);
}
.nav-link.active {
  font-size: 18px;
  background-image: none;
  border-color: #dee2e6 #dee2e6 #fff !important;
}
.nav-link {
  border-color: #dee2e6 !important;
}
.nav.nav-tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
  gap: 0.5rem;
}
.nav-tabs {
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #354052;
  font-family: inherit;
  border: none !important;
}
.calldetails-slider--grid > .tab-content {
  // border: solid 1px #e6eaee;
  border-radius: 6px;
  border-top: none;
}
.calldetails-slider--grid {
  margin: 24px;
}
.calldetails-slider--grid--top {
  background-color: white;
  // height: 18.75rem;
  display: grid;
  border: 1px solid #e6eaee;
  border-radius: 6px;
  border-top-left-radius: 0px;
}

.calldetails-calltitle--patientform {
  width: 500px;
  border-left: 1px solid #e6eaee;
  margin: 1rem;
}

.toggle-contact-detail-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-size: 13px;
  color: #bbc5d5;
  border: 2px solid #bbc5d5;
  cursor: pointer;
  transition: 0.5s;
}

.toggle-contact-detail-button:focus {
  outline: none;
}

.dispensary-name-tag {
  width: 77px;
  height: 24px;
  border-radius: 4px;
  // background-color: #1bb934;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  font-family: Source Sans Pro;
  text-transform: uppercase;
  margin: 2rem 0rem;
}
.call-info {
  display: grid;
  grid-template-columns: 87px auto;
  margin: 2rem 2rem 0rem;
  text-align: left;
  opacity: 0.44;
  font-family: Source Sans Pro;
  font-size: 13px;
  color: #1c1c1c;
}

.call-timer {
  margin: 2rem 0rem;
  font-size: 1.5rem;
}
.call-buttons {
  margin: 5rem 1rem 0;
  height: 50px;
  display: grid;
  grid-template-columns: auto 50px auto;
  grid-gap: 10px;
  justify-items: center;
  button {
    font-size: 15px;
    background-color: #fff;
    color: #bbc5d5;
    border: none;
    box-shadow: none;
  }
  button.end-call-button {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
  }
  button.end-call-button:active {
    box-shadow: none;
  }
  button:focus {
    outline: none;
  }
  .end-call-button {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #ef5754;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
  }
}

.dark {
  .nav-link.active, .nav-link {
    background: $darkGradient1;
    color: $light1 !important;
    border-bottom: 0px;
  }

  .nav-link {
    border: 1px solid $dark2 !important;
  }

  .nav-link.active {
    background:none;
  }

  .call-slideout {
    background-color: $dark4 !important;
  }

  .connect-div--show-call {
    background-color: $dark2;
  }

  .call-info {
    color:$light2;
  }
} 