@import "colors.scss";

.dashboard-main {
  display: grid;
  grid-template-rows: 1fr 2fr 1fr 5fr 3fr;
}

.dashboard {
  background-color: $light1;
  display: grid;
  // grid-template-rows: 1fr 1fr 1fr 4fr 4fr 4fr;
  grid-gap: 20px;
  padding: 0rem 2rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  // margin-top: 2rem;
}
.dashboard-name-date {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0rem;
  align-items: center;
  text-align: left;
}
.dashboard-name-date > p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  justify-self: flex-end;
}
.dashboard-name-date > h1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  display: inline;
  justify-self: flex-start;
}
.dashboard-date {
  display: inline;
}

.dashboard-queue-stats {
  // grid-gap: 1.25rem;
  // display: grid;
  // grid-template-columns: 35.8125rem 26.75rem;
  // grid-template-columns: repeat(auto-fill, 450px);
  // height: 318px;
  margin-top: 3rem;
  display: flex;
  // max-width: 860px;
  @media only screen and (max-width: 1220px) {
    flex-direction: column;
  }
}
.dashboard-queue-stats > * {
  background-color: #ffffff;
}

.dashboard-queue--element-number {
  font-weight: 600;
  font-size: 1.3125rem;
}
.dashboard-queue--element-name {
  border-radius: 4px;
  // background-color: #1bb934;
  height: 1.5rem;
  justify-self: center;
  align-self: center;
  color: white;
}
.dashboard-queue--element-cell > p {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dashboard-queue--element-cell-status {
  display: flex;
  align-items: center;
}

.dashboard-queue--element {
  display: grid;
  text-align: left;
  // grid-template-columns: auto auto auto auto auto auto;
  grid-template-columns: 25px 90px 27px auto 120px;
  grid-auto-columns: minmax(max-content, 6fr);
  align-content: center;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 1rem;
  height: 54px;
  border-radius: 4px;
  background-color: $light1;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.875rem;
}
.dashboard-queue--element > * {
  padding: 0 0.5rem;
}

.dashboard-queue--element:focus {
  outline: none;
}

.dashboard-table--element {
  display: grid;
  // display: flex;
  grid-template-columns: 59px 87px 47px 64px;
  padding: 16px;
  justify-content: space-between;
  margin: 8px;
  background-color: $light1;
}
.dashboard-table--element > * {
  text-align: left;
  max-width: 50px;
}

.dashboard-table--element:not(:first-child) {
  background-color: white;
}
.dashboard-quickstats {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (min-width: 1220px) {
    max-width: 430px;
  }
}
.dashboard-quickstats--box > img {
  width: 36px;
  justify-self: self-end;
  align-self: center;
}
.dashboard-quickstat-icon {
  width: 36px;
  justify-self: self-end;
  align-self: center;
}
.quickstat-label {
  display: block;
  width: 114px;
  height: 17px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808ba7;
  position: relative;
  left: 50%;
  top: 33%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.dashboard-quickstats > h2 {
  height: 25px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #010101;
  padding-left: 0.4375rem;
  padding-bottom: 1.125rem;
}
.dashboard-queue-list {
  margin-top: 1rem;
}
.dashboard-quickstats-grid {
  display: grid;
  grid-gap: 14px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-top: 1rem;
  justify-items: center;
}
.quickstat-label-number {
  display: block;
  font-size: 36px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: black;
}
.dashboard-quickstats--box {
  display: grid;
  grid-template-columns: auto auto;
  width: 192px;
  height: 108px;
  border-radius: 4px;
  background-color: $light1;
}

.dashboard-cases {
  display: flex;
  section {
    display: flex;
    flex: 1;
  }
  @media only screen and (min-width: 1500px) {
    section:nth-of-type(1) {
      margin-right: 1rem;
      padding-right: 1rem;
    }
  }
  // TODO this needs to adjust based on whether phone or email sidebar is open
  @media only screen and (max-width: 1500px) {
    flex-direction: column-reverse;
  }
}

p {
  margin: 0 !important;
  margin-top: 0 !important;
}

.nav.nav-tabs {
  grid-template-columns: 300px 300px 300px;
}


.dark {
  .dashboard {
    background-color: $dark1;
  }  
}