.red
{
    color:red;
}
.green 
{
    color:green;
}

.cart-box {
  display: block;
  padding: 15px;
  margin-top: 15px;
}

.cart-item {
  text-align: left;
  align-items: center;
}

.cart-items-list {
  position: inherit;
}

.item-count {
  max-width: 30px;
}

.cart-product-search {
  height: 40px;
  padding: 0px 20px;
  width: 100%;
}

.cart-product-search-result, .cart-items-list {
  display: block;
  position: initial;
  left: 0px;
  width: 100%;
  top: 90px;
}

.cart-product-search-result {
  position: absolute;
}