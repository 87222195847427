#app-login {
  background: url(/images/login-bck@3x.jpg) no-repeat center center fixed;
  min-height: 100vh;
  min-width: 100vw;
}
.app-login-wrapper,
.login-button,
.login-input {
  border-radius: 0.25rem;
}
.app-login-wrapper,
.login-form-top,
.login-form-bottom {
  width: 28.125rem;
}
.login-form-top,
.login-input {
  background-color: #f8fafc;
}
.login-form-top > span,
.login-input,
.login-label {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}
.login-form-top > span,
.login-input {
  color: #1c1c1c;
}
.login-form-top > span,
.login-label {
  display: block;
}
.login-input,
.login-label,
.login-button {
  font-size: 0.875rem;
}
.login-input,
.login-button {
  width: 24.375rem;
  height: 2.875rem;
}
.login-input,
.login-label {
  font-weight: 600;
  line-height: 1.36;
}
.app-login-wrapper {
  // position: absolute;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // border: solid 1px #e6eaee;
}
.login-form-top {
  height: 270px;
}
.login-form-top > img {
  object-fit: contain;
  margin-top: 38px;
  width: 14.75rem;
  // height: 210px;
}
.login-form-top > span {
  font-size: 24px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  margin-top: 1rem;
}
.login-form-bottom {
  background-color: #ffffff;
}
.login-form-bottom > form {
  padding: 1.875rem;
}
.login-input {
  border: solid 1px #dfe3e9;
  padding-left: 0.8125rem;
}
.login-label {
  width: 12.0625rem;
  height: 1.1875rem;
  text-align: left;
  color: #7f8fa4;
  margin: 16px 0 5px;
}
.login-button {
  border: solid 1px #19FF59;
  background-image: linear-gradient(to bottom, #19FF59, #00d23a);
  margin-top: 1.25rem;
  color: white;
  font-weight: 600;
}

.app-login-wrapper .forgot-password {
  color: #ffffff !important;
  font-size: 0.875rem;
  float: right;
  line-height: 2.36;
}

.round-border {
  margin: 5%;
  padding: 5px !important;
  border-radius: 10px !important;
}
