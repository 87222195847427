@import './colors.scss';

.date-range-buttons {
  font-size: 1em;
  background: $lightGradient1;
  padding: 20px;
  width: 150px !important;
  text-align: center;
}

.dashboard {
  thead {
    background-color: $light4;
  }
}

.doughnut-chart {
  width: 65% !important;
  height: auto !important;
  margin: 40px auto;
  @media screen and (min-width: 1600px) {
    width: 50% !important;
  }
  @media screen and (min-width: 1900px) {
    width: 50% !important;
  }
}
.top-requested-products
{
  display: grid;
  grid-template-columns: auto auto ;
}
.chart-list {
  width:90%;
  margin: 0px auto;
  text-align: left;
  padding: 20px;
  padding-top: 10px;
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 35px;
  
  li {
    margin-top: 15px;
    margin-bottom: 17px;

    b {
      line-height: 2;
    }
  }

  li:nth-child(4n+1)>.progress>.progress-bar {
    background-color: #17a2b8 !important;
  }
  li:nth-child(4n+2)>.progress>.progress-bar {
    background-color: #ffc107 !important;
  }
  li:nth-child(4n+3)>.progress>.progress-bar {
    background-color: #28a745 !important;
  }
  li:nth-child(4n+4)>.progress>.progress-bar {
    background-color: #dc3545 !important;
  }
}

.top-requested-product {
  padding:20px;

  table {
    width:100%;
  }

  .progress {
    margin:10px auto;
  }

  .strain-name {
    text-align: left;
    font-size: 1.1em;
  }

  .product-type {
    text-align: left;
    font-size: 0.85em;
    color: $light5;
    height: 50px;
    width: 65%;
  }

  .percentage {
    text-align: right;
    font-size: 1.5em;
    font-weight: 700;
  }

  .sales-number {
    text-align: left;
    font-size: 0.85em;
    color: $light5
  }
}

.card-header b {
  padding-left: 15px;
}

.question-answer-background {
  background-color: $light1;
  padding-bottom: 20px !important;
}

.question-answer-box {
  background-color: white;
  max-width: 90%;
  margin-top: 20px !important;
  border:1px solid $light5;
  border-radius: 5px;
  padding: 10px;

  .question {
    font-size: 1em;
    font-weight: 700;
  }
}

.question, .answer {
  width:100%;
  display: block;
    text-align: left;
}


.dark {
  tr {
    background-color: unset !important;
  }
  .question-answer-background {  
    background-color: $dark2;
  }
  .question-answer-box {
    background-color: $dark3;
    color: $light2;
    border-color: $dark1;
  }
  .chart-list {
    color:  $light2;
  }
  .dashboard {
    .table th {
      background-color: $dark1;
      color: $light1;
      border-bottom : 1px solid $dark1 !important;
      border-top: 1px solid $dark1 !important;
    }
    .table tr:nth-child(even) {
      background-color: $dark3;
    }
    .table tr:nth-child(odd) {
      background-color: $dark1;
    }
  }
}