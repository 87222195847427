.outer-container {
  background-color: white;
  margin: 0 auto;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  border-top-left-radius: 0px;
}
.patient-detail--inner {
  display: flex;
  flex: 1;
  position: relative;
}

.left-style {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid #e6eaee;
  justify-content: space-between;
  padding: 0rem 2rem;
  > div:not(:nth-of-type(1)) {
    margin-top: 1rem;
  }
  .name-inputs {
    display: flex;
    > div:nth-of-type(1) {
      margin-right: 0.5rem;
    }
    > div:nth-of-type(2) {
      margin-left: 0.5rem;
    }
  }
}

.right-style {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 0rem 2rem;
  > div:not(:nth-of-type(1)) {
    margin-top: 1rem;
  }
}

.input-box {
  border: 1px #dfe3e9 solid;
  border-radius: 4px;
  height: 36px;
  display: flex;
  margin-top: 6px;
  flex: 1;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 600;
  color: #354052;
  padding-left: 12px;
  width: 100%;
}

.account-ballance {
  font-family: 'Source Sans Pro';
  font-size: 26px;
  font-weight: 600;
  color: #354052;
}

.ballance-description {
  font-family: 'Source Sans Pro';
  font-size: 11px;
  font-weight: 600;
  color: #3f51b5;
  margin-left: 10px;
}

.input-container {
  text-align: left;
  font-family: 'Source Sans Pro';
  color: #7f8fa4;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 63px;
}

.input-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.save-button {
  position: absolute;
  right: 25px;
  border-radius: 50%;
  height: 50px;
  top: -25px;
  width: 50px;
  cursor: pointer;
  font-size: 25px;
  color: #19FF59;
  border-style: none;
  border: 1px solid #dee2e6;
  // box-shadow: #959595 0 2px 5px;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 16px rgba(0, 0, 0, 0);
  }
}

.save-button:focus {
  outline: none;
}
.save-button:active {
  outline: none;
  // animation: shadow-pulse 0.25s;
}

.react-datepicker__input-container > input {
  border: 1px #dfe3e9 solid;
  border-radius: 4px;
  height: 36px;
  display: flex;
  margin-top: 6px;
  flex: 1 1;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 600;
  color: #354052;
  padding-left: 12px;
  width: 100%;
}
.react-datepicker-wrapper {
  display: block !important;
}
.account-balance-container {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
}
.input-container--date > p {
  margin: 0;
}
.input-container--date {
  display: block;
}
.inner-container {
  display: flex;
  flex: 1;
  margin: 2rem 0rem;
}

@media only screen and (max-width: 1280px) {
  .inner-container {
    flex-direction: column;
  }
}

#call-details-tabpane-patientCases {
  border-top: 1px solid #dee2e6;
  border-top-right-radius: 4px;
}
