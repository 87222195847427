@import './colors.scss';

.themeSwitch {
    cursor: pointer;
    position: fixed;
    right: 100px;
    top: 10px;
    font-size: 1.5rem;
    line-height: 40px;
}

.fullscreenButton {
    font-size: 1.5rem;
    position: fixed;
    top: 10px;
    right: 75px;
    line-height: 40px;
}

.settingsIcon {
    color: #41435c;
    position: fixed;
    top: 10px;
    right: 20px;
    font-size: 1.5rem;
    z-index: 2;
    line-height: 40px;
    opacity: 0.2;
}

.settingsIcon:hover {    
    opacity: 1;
}

.settingsBox:hover, .settingsIcon:hover + .settingsBox {
    display: block;
    width: 150px;

}

.settingsBox {
    display: none;
    height: 40px;
    width:45px;
    background: $lightGradient1;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1;
    border-radius: 50px;
    border:1px solid $dark4;
    cursor: pointer;
    transition: width 1s;
}


.dark {
    .settingsBox{
        background: $darkGradient1;
        color:$light1
    }

    .settingsIcon {color:$light1}
}