@import 'colors.scss';
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background: $lightGradient1;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}

.dark {
    .modal-content {
        background: $darkGradient1;   
    }
}
