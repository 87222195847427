@import "colors.scss";

.quickpatients {
  width: 1021px;
  padding: 0;
  margin: 0 33px;
  text-align: left;
}
.quickpatients > * {
  padding: 0;
  margin: 0;
  min-width: 100%;
}
.patients {
  text-align: left;
  margin: 0;
  padding: 0rem 2rem;
  width: 100%;
}
.patients-table-data--type > svg {
  margin-right: 5px;
}
.padding-left.patient-parent {
  padding-left: 19rem;
}
.patients-table--body > tr {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  // grid-template-columns: auto auto auto auto auto auto auto;
  grid-gap: 1rem;
  grid-auto-columns: minmax(max-content, 6fr);
  margin-top: 1rem;
  height: 56px;
  padding-left: 1rem;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
}
.patients-table {
  width: 100%;
}
.patients > h2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 28px;
  font-weight: 600;
  color: #0c0f13;
  margin-top: 2rem;
}

.patients-table--body > tr:not(:first-child) {
  border-radius: 4px;
  border: solid 1px 1px 1px 0 #e6eaee;
  // border-left: solid 0.25rem red;
  background-color: white;
}
.patients-table-data--priority-1 {
  border-left: solid 0.25rem green;
}
.patients-table-data--priority-2 {
  border-left: solid 0.25rem orange;
}
.patients-table-data--priority-3 {
  border-left: solid 0.25rem red;
}
.patients-table-data-priority-text-1 {
  color: green;
  font-weight: bold;
}
.patients-table-data-priority-text-2 {
  color: orange;
  font-weight: bold;
}
.patients-table-data-priority-text-3 {
  color: red;
  font-weight: bold;
}
.patients-table-data,
.patients-table-headers {
  align-items: center;
}
.patients-table-data--type > img {
  width: 1.25rem;
  padding-right: 0.25rem;
}
.patients-table-data--dispensary {
  border-radius: 4px;
  // padding: 0.5rem;
  height: 1.5rem;
  justify-self: left;
  color: white;
  width: 100%;
  display: block;
  line-height: 1.5rem;
}
.dispensary-1 {
  background-color: #1bb934;
}
.dispensary-2 {
  background-color: #18a5d6;
}
.dispensary-3 {
  background-color: #0c0f13;
}
.patients-table-data--dispensary > a {
  position: relative;
  padding: 0 0.5rem;
  color: #fff;
}
.patients-table-headers {
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8fa4;
  font-family: 'Source Sans Pro', sans-serif;
}

.MuiGrid-spacing-xs-1 {
  margin: 0px !important;
}

.makeStyles-root-1 {
  margin-left: 10px !important;
}

.makeStyles-root-15 {
  margin-left: 10px !important;
}

.btn-outline-info, .btn-outline-info:hover {
  color: #888 !important;
  background: linear-gradient(0deg,#f4f7fa,#fff) !important;
  box-shadow:none !important;
  font-weight: 700;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show>.btn-outline-info.dropdown-toggle {
  color: #888;
  background: linear-gradient(0deg,#f4f7fa,#fff) !important;
  border-color: #ccc;
  box-shadow:none;
}

.dark {
  .btn-outline-info, .btn-outline-info:hover,
  .btn-outline-info:not(:disabled):not(.disabled).active,
  .btn-outline-info:not(:disabled):not(.disabled):active,
  .show > .btn-outline-info.dropdown-toggle, .input-group-text
  {
    background: $darkGradient1 !important;
    border: 1px solid $dark1;
    color: $light4;
  }
}