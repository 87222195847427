.email-thread {

}

.received-email
{
}

.sent-email
{
}

.email-sender-name {
  padding-left: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
}

.email-container {
  text-align: left;
  border-bottom: 1px solid #00000022;
  padding: 1rem;
  overflow-wrap: anywhere;
}

.email-container:hover {
  background-color: aliceblue;
  cursor:pointer;
}

.email-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.email-from {
  > div {
    font-weight: 600;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-weight: bold;
    color: #354052;
    margin: 5px 0px -5px 0px;
  }
  > span {
    font-size: 14px;
    font-weight: 700;
  }
}
.email-time {
    width: 100%;
    display: block;
    text-align: right;
    margin-bottom:5px;
  > span {
    font-size: 14px;
    font-family: 'Source Sans Pro';
    color: #aeb6c5;
    > span:nth-of-type(1) {
      font-weight: bold;
    }
  }
}

.email-subject {
  opacity: 0.67;
  font-family: 'Arial';
  font-size: 14px;
  font-weight: 700;
  color: #354052;
  margin: 10px 0px 10px 0px;
}

.email-body {
  opacity: 0.67;
  font-family: 'Source Sans Pro';
  font-size: 13px;
  color: black;

  white-space: nowrap; 
  height: 30px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.email-sender-emailId {padding-left: 1rem;}

.modal-title {padding: 1rem;}
.modal-title.h4 {font-size: larger;}
.modal-header {
  grid-template-columns: 1fr 10fr 1fr;
}

.email-icon {
  height: 3.5rem;
  width: 3.5rem;
  color:white;
  border-radius: 50%;
  line-height: 3.5rem;
  font-weight: 700;
  font-size: x-large;
  text-align: center;
  grid-row: 1 / 3;
}

.email-icon-list {
  height: 2.5rem;
  width: 3rem;
  line-height: 2.5rem;
  font-size: large;
  color:white;
  border-radius: 50%;
  text-align: center;
  font-weight: 700;
}

.email-body p{
  white-space: nowrap; 
  height: 30px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.reply-to-email-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-size: 13px;
  color: #bbc5d5;
  border: 2px solid #bbc5d5;
  cursor: pointer;
}

.reply-to-email-button:focus {
  outline: none;
}

#email-body-text-area {
  height: 135px;
  border-radius: 4px;
  border: solid 1px #e6eaee;
  background-color: #ffffff;
  flex: 1;
  // margin: 1rem;
  padding: 1rem;
  position: relative;
  font-family: 'Source Sans Pro';
  font-size: 14px;
}

#email-body-text-area::placeholder {
  opacity: 0.73;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  color: #354052;
}

#email-body-text-area:focus {
  outline: none;
}

button {
  box-shadow: none;
}

.email-sidebar-container {
  position: relative;
  width: 20rem;
  height: 100%;
  // padding-top: 5vh;
  background-color: #fff;
  border-left: 1px solid rgb(227, 230, 234);
  padding: 0px 5px;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.email-sidebar-header {
  height: 63px;
  padding-left: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e3e6ea;
}

.email-modal {
  .modal-header {
    background-image: linear-gradient(to top, #f4f7fa, #ffffff);
    .modal-title {
      font-family: 'Source Sans Pro';
      font-size: 26px;
      color: #000000;
    }
    .close {
      font-size: 39px;
      font-weight: 400;
    }
  }
  .modal-body {
    label {
      font-family: 'Source Sans Pro';
      font-size: 15px;
      font-weight: 600;
      color: #354052;
    }
    input,
    textarea {
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-weight: 600;
      line-height: 1.36;
      color: #354052;
    }
  }
}

.no-emails-placeholder {
  font-family: 'Source Sans Pro';
  display: flex;
  justify-content: center;
  margin-top: 25vh;
  color: #7f8fa4;
}

.toggle-contact-detail-button1 {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-size: 13px;
  color: #bbc5d5;
  border: 2px solid #bbc5d5;
  cursor: pointer;
  transition: 0.5s;
}
